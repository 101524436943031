<template>
  <div id="page" class="un-authenticated">
    <!-- Top fixed navigation -->
    <Navigation />
    <!-- Page Header -->
    <!-- Body -->
    <router-view />
    <!-- Page Footer -->
  </div>
</template>

<script>
import Navigation from 'components/Navigation';

export default {
  name: 'UnAuthenticatedLayout',
  components: { Navigation },
  created() {
    // console.log('UnAuthenticatedLayout created()');
    this.$store.dispatch('auth/logout');
  },
};
</script>

<style lang="scss"></style>
